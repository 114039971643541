import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image'

import styles from '../styles/content.module.scss';

const Content = ({ children, title, slug, textColor, intro, image, body, bodyColumnTwo, bodyColumnThree }) => (
  <div
  	id={slug}
    className={styles.content}
  >
  	<div className={styles.introOuterContainer}>
			<div className={styles.intro}>
				<div className={[styles.introInnerContainer, !!image && styles[`introHasImageIs${textColor}`], !!image && styles.introHasImage].join(' ')}>
					<h1 className={styles.introTitle}>
						{title}
					</h1>
					{!!intro && <Html {...intro.childMarkdownRemark} />}
				</div>
			</div>

			{!!image && <div className={styles.image}>
				<div className={styles.imageInnerContainer}>
					<Img {...image} />
				</div>
			</div>}
		</div>

		{children}

		<div className={styles.body}>
			{!!body && <Html {...body.childMarkdownRemark} />}
			{!!bodyColumnTwo && <Html {...bodyColumnTwo.childMarkdownRemark} />}
			{!!bodyColumnThree && <Html {...bodyColumnThree.childMarkdownRemark} />}
		</div>
  </div>
);

const Html = ({ html }) => (
	<div
		className={styles.html}
	  dangerouslySetInnerHTML={{
	    __html: html,
	  }}
	/>
);

Content.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  textColor: PropTypes.string,
}

Content.defaultProps = {

}

export default Content

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image'

import { CaseCard } from './cases';

import styles from '../styles/content.module.scss';

const Case = ({ name, role, slug, portrait, quote, image, body }) => (
  <div
  	id={slug}
    className={styles.content}
  >
  	<div className={styles.introOuterContainer}>
			{!!image && <div className={styles.image}>
				<div className={styles.imageInnerContainer}>
					<Img {...image} />
				</div>
			</div>}

			<div className={styles.intro}>
				<div className={[styles.introInnerContainer].join(' ')}>
					<div className={styles.introCard}>
						<CaseCard
							name={name}
							role={role}
							portrait={portrait}
						/>
					</div>
					<p className={styles.quote}>{quote}</p>
				</div>
			</div>
		</div>

		<div className={styles.body}>
			{!!body && <Html {...body.childMarkdownRemark} />}
		</div>
  </div>
);

const Html = ({ html }) => (
	<div
		className={styles.html}
	  dangerouslySetInnerHTML={{
	    __html: html,
	  }}
	/>
);

Case.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  textColor: PropTypes.string,
}

Case.defaultProps = {

}

export default Case

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Case from '../components/case';
import Cases from '../components/cases';

const CustomerCasePage = ({ pageContext, data }) => {
  return <Layout>
    <SEO title={pageContext.title} keywords={[]} />
    <Case {...data.contentfulCustomerCase} />
    <Cases slug='andere-ervaringen' />
  </Layout>
}

CustomerCasePage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    contentfulCustomerCase: PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
}

export const pageQuery = graphql`
  query($id: String!) {
    contentfulCustomerCase(id: { eq: $id }) {
      id
      slug
      name
      role
      quote
      portrait {
        fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      image {
        id
        title
        fluid(maxWidth: 1440, toFormat: JPG, quality: 75) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default CustomerCasePage

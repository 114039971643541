import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Navigation } from './navigation';

import logo from '../images/logo-nom.png';
import styles from '../styles/header.module.scss';

const Header = ({ siteTitle }) => (
  <div
    className={styles.header}
  >
    <div
      className={styles.headerInnerContainer}
    >
      <div className={styles.logo}>
        <h1>
          <Link
            to='/'
          >
            <img src={logo} alt='Nationale Onderwijs Monitors' />
            <span className={styles.siteTitle}> {siteTitle}</span>
          </Link>
        </h1>
      </div>
      <Navigation />
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header

import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu'

import styles from '../styles/navigation.module.scss';
import '../styles/navigation.react-burger-menu.scss';

const Navigation = () => (
  <React.Fragment>
    <div className={styles.desktop}>
      <MainNavigation />
    </div>
    <div className={styles.mobile}>
      <Menu
        right
        width={'195px'}
      >
        <MainNavigation />
      </Menu>
    </div>
  </React.Fragment>
)

const MainNavigation = () => (
  <nav className={styles.navigation}>
    <Link to='/'>Home</Link>
    {/*<Link to='/ons-aanbod'>Ons aanbod</Link>
    <Link to='/onze-klanten'>Onze klanten</Link>
    <Link to='/over-ons'>Over ons</Link>*/}
    <Link to='/contact' className={styles.contact}>Contact</Link>
    <a
      href='https://nom.nationaleonderwijsmonitors.nl/Invullen'
      target='_blank'
      className={styles.survey}>
        Invullen
    </a>
    <a
      href='https://nom.nationaleonderwijsmonitors.nl/Administration/Credentials/Login'
      target='_blank'
      className={styles.login}>
        Inloggen
    </a>
  </nav>
)

const FooterNavigation = () => (
  <nav className={styles.footerNavigation}>
    <ul>
      {/*<li>
        <Link to='/ons-aanbod'>Ons aanbod</Link>
        <ul>
          <li>
            <Link to='/ons-aanbod#talentvolle-mensen'>
              Onderzoek
            </Link>
          </li>
          <li>
            <Link to='/ons-aanbod#innovatieve-tools'>
              Ontwikkeling
            </Link>
           </li>
          <li>
            <Link to='/ons-aanbod#implementatie'>
              Implementatie
            </Link>
          </li>
        </ul>
      </li>*/}
      {/*<li>
        <Link to='/onze-klanten'>
          Onze klanten
        </Link>
        <ul>
          <li>
            <Link to='/onze-klanten#klanten-lijst'>
              Kenniscentra
            </Link>
          </li>
          <li>
            <Link to='/onze-klanten#klanten-lijst'>
              Profit en non-profit
            </Link>
          </li>
        </ul>
      </li>*/}
      {/*<li>
        <Link to='/over-ons'>
          Over ons
        </Link>
        <ul>
          <li>
            <Link to='/over-ons#ons-doel-en-onze-droom'>
              Ons doel en onze droom
            </Link>
          </li>
          <li>
            <Link to='/over-ons#ons-team'>
              Ons team
            </Link>
          </li>
          <li>
            <Link to='/over-ons#onze-partners'>
              Vacatures
            </Link>
          </li>
        </ul>
      </li>*/}
      <li>
        <Link to='/contact'>Contact</Link>
      </li>
      <li>
        <a
          href='https://nom.nationaleonderwijsmonitors.nl/Invullen'
          target='_blank'
          className={styles.survey}>
            Invullen
        </a>
      </li>
      <li>
        <a
          href='https://nom.nationaleonderwijsmonitors.nl/Administration/Credentials/Login'
          target='_blank'
        >
            Inloggen
        </a>
      </li>
    </ul>
  </nav>
)

export { Navigation, FooterNavigation }

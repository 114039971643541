import React from 'react'

import styles from '../styles/card.module.scss';

const Card = ({ frontside, backside }) => {
  return <div className={[styles.card, !!backside && styles.canFlip].join(' ')}>
    <div className={[styles.cardInnerContainer, !!backside && styles.hasBackside].join(' ')}>
      <div className={styles.frontside}>
        {frontside}
      </div>
      {!!backside && <div className={styles.backside}>
        {backside}
      </div>}
    </div>
  </div>
}

export default Card

import React from 'react'
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

import Card from './card';
import Content from './content';

import styles from '../styles/cases.module.scss';

const Cases = ({ slug }) => {
	return <StaticQuery
	  query={casesQuery}
	  render={ data => {
	    const cases = data.allContentfulCustomerCase.edges.map(edge => {
	      return <CaseListItem key={edge.node.id} {...edge.node} />
	    })
	    return <div className={styles.cases}>
				<Content
					slug={slug}
					title='Ervaringen van onze klanten'
				/>
				<div className={styles.caseList}>
					{cases}
				</div>
				</div>
	  }}
	/>
}

const CaseListItem = (data) => {
	return <Link
    className={styles.caseListItem}
    to={`ervaringen/${data.slug}`}
  >
		<CaseCard {...data} more='Lees meer...' />
	</Link>
}

const CaseCard = (data) => {
  return <div className={styles.caseCard}>
    <Card frontside={<CaseCardFrontside {...data} />} />
  </div>
}

const CaseCardFrontside = ({ name, role, portrait, quote, more }) => {
  return <React.Fragment>
    <div className={styles.image}>
      {!!portrait && <Img {...portrait} />}
    </div>
    <h3>{name}</h3>
    <p className={styles.role}>{role}</p>
    {!!quote && <p className={styles.quote}>{quote}</p>}
    {!!more && <p className={styles.more}>{more}</p>}
  </React.Fragment>
}

Cases.propTypes = {
  slug: PropTypes.string.isRequired,
}

const casesQuery = graphql`
  query Cases {
    allContentfulCustomerCase {
      edges {
        node {
          id
          slug
          name
          role
          quote
          portrait {
            id
            title
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export { Cases as default, CaseCard };
